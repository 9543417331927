import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    forgotPasswordStatus: 'idle',
    agencyUsers: [],
    agencyUser: null,
    meta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
};
// ----------------------------------------------------------------------
export const getAgencyUsers = createAsyncThunk('agencyUser/getAgencyUsers', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', filterUser = [], isPaging = true, }) => {
    let data;
    const queryParams = {
        search: {
            fullName: filterName,
            email: filterName,
            phone: encodeURIComponent(filterName),
            'agencyUser.agency.id': filterUser,
        },
        searchJoin: 'OR',
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    try {
        const url = createDynamicURL('/users', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createUser = createAsyncThunk('agencyUser/createUser', async (Data) => {
    let data;
    delete Data.id;
    try {
        const response = await axios.post('/users', Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const updateAgencyUser = createAsyncThunk('agencyUser/updateAgencyUser', async (Data, thunkAPI) => {
    const { id, ...rest } = Data;
    let data;
    try {
        const agencyUser = {
            firstName: Data?.firstName || '',
            lastName: Data?.lastName || '',
            email: Data?.email || '',
            phone: Data?.phone || '',
            address: Data?.address || '',
            startWorkingDate: Data?.startWorkingDate || '',
            notes: Data?.notes || '',
            roleId: Data?.roleId || '',
            groups: Data?.groups || '',
            permissions: Data?.permissions || '',
        };
        const filteredValues = Object.fromEntries(Object.entries(agencyUser).filter(([key, value]) => value !== ''));
        const response = await axios.put(`/users/${id}`, filteredValues);
        data = await response.data;
        if (response.status === 200) {
            return {
                data,
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getOneAgencyUsers = createAsyncThunk('agencyUser/getOneAgencyUser', async (id) => {
    let data;
    try {
        const response = await axios.get(`/users/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return { data };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const deleteAgencyUsers = createAsyncThunk('agencyUser/deleteAgencyUsers', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/users/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getAgencyUsers({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyUsers = createAsyncThunk('agencyUser/deleteManyAgencies', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/users/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getAgencyUsers({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const resetPassword = createAsyncThunk('agencyUser/resetPassword', async (Data) => {
    let data;
    try {
        const response = await axios.put('/users/resetPassword', Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const forgotPassword = createAsyncThunk('agencyUser/forgotPassword', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/users/forgotPassword?language=${Data.language}`, {
            email: Data.email,
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const resendConfirmationLink = createAsyncThunk('agencyUser/resendConfirmationLink', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/users/resendConfirmationLink?language=${Data.language}`, {
            email: Data.email,
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'agencyUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAgencyUsers.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAgencyUsers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.agencyUsers = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getAgencyUsers.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createUser.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createUser.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteAgencyUsers.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteAgencyUsers.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteAgencyUsers.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(updateAgencyUser.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(updateAgencyUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(updateAgencyUser.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneAgencyUsers.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneAgencyUsers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.agencyUser = action.payload.data.data;
        })
            .addCase(getOneAgencyUsers.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(forgotPassword.pending, (state) => {
            state.forgotPasswordStatus = 'loading';
        })
            .addCase(forgotPassword.fulfilled, (state, action) => {
            state.forgotPasswordStatus = 'succeeded';
        })
            .addCase(forgotPassword.rejected, (state, action) => {
            state.forgotPasswordStatus = 'failed';
        })
            .addCase(resendConfirmationLink.pending, (state) => {
            state.forgotPasswordStatus = 'loading';
        })
            .addCase(resendConfirmationLink.fulfilled, (state, action) => {
            state.forgotPasswordStatus = 'succeeded';
        })
            .addCase(resendConfirmationLink.rejected, (state, action) => {
            state.forgotPasswordStatus = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
